import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  Junior web developer with one year experience in coding. I successfully completed
                  CodersLab&#39;s Front-End Developer React Bootcamp. Curently I improve my
                  knowledge in Web technology&#39; s (React, JavaScript, HTML and CSS). I am focused
                  on expanding my web developer skills. I am looking for new challenges.
                </p>
                <p className="about-wrapper__info-text">
                  I am self-motivated and hard-working person, on a daily bases I enjoy working
                  closely with other people and solving problems.{' '}
                </p>
                <p className="about-wrapper__info-text">
                  I appreciate your time and consideration and hope that my abilities and experience
                  align with your needs. I believe my skills and motivation make me a great
                  potential asset. I can be reached at{' '}
                  <a
                    style={{ textDecoration: 'none', color: 'black' }}
                    href="mailto:bozejewski@gmail.com"
                  >
                    bozejewski@gmail.com
                  </a>{' '}
                  if you need any other information.
                </p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                      download="Pawel_Bozejewski_CV.pdf"
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
