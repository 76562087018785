import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import cv from '../../pdf/cv.pdf';

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || ''}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}

          <a
            key="cv"
            href={cv}
            rel="noopener noreferrer"
            target="_blank"
            download="Pawel_Bozejewski_CV.pdf"
          >
            <i className="fa fa-file-pdf-o fa-inverse" />
          </a>
        </div>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()} - Portfolio developed by{' '}
          <a href="https://github.com/Mojzesz13 " target="_blank" rel="noopener noreferrer">
            Bozejewski
          </a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
